import Link from "next/link";
import React, { useState, Fragment } from "react";
import { url, companyItems } from "./types";
import { resources, skinConditions, company } from "./constants";
import ExpandCard from "./expandCard";

const Footer = () => {
  const [expandedList, setExpandedList] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  return (
    <footer className="px-6 lg:px-16 pt-8 lg:pt-9 pb-32 lg:pb-8 max-w-screen-2xl mx-auto">
      <div className="flex flex-col flex-wrap lg:flex-row  items-start justify-between">
        <div className="flex flex-col w-auto">
          <img
          width={160}
          height={48}
            className="lg:w-40 lg:h-12 lg:mb-6 w-36 mb-6"
            alt="RemoteDerm Logo"
            src={"https://cdn.remotederm.ca/images/logo_text.webp"}
          />
          <span className="font-sans-pro-regular color-828282 line-spacing-24 font-normal font-size-16">
            {"Call to Support"}
          </span>
          <span className="mb-6 font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-16">
            {"+1 (877) 754 6356"}
          </span>
          <span className="font-sans-pro-regular color-828282 line-spacing-24 font-normal font-size-16">
            {"Fax"}
          </span>
          <span className="mb-6 font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-16">
            {"+1 (866) 984 4046"}
          </span>
          <span className="font-sans-pro-regular color-828282 line-spacing-24 font-normal font-size-16">
            {"Contact Email"}
          </span>
          <span className="mb-6 font-sans-pro-semi-bold color-150910-secondary-1 font-semibold font-size-16">
            {"support@RemoteDerm.ca"}
          </span>
        </div>
        <ExpandCard
          title={"Resources"}
          expanded={expandedList[0]}
          setExpanded={() => {
            let list = JSON.parse(JSON.stringify(expandedList));
            let index = list.findIndex((x: boolean) => x === true);
            if (index !== 0) {
              list[index] = false;
            }
            list[0] = !list[0];
            setExpandedList(list);
          }}
        >
          {resources.map((item: any, index: number) => (
            <Fragment key={index}>
              <Link
                href={item.page}
                className="cursor-pointer font-sans-pro-regular color-150910-secondary-1 font-normal mb-3 font-size-14">

                {item.text}

              </Link>
            </Fragment>
          ))}
        </ExpandCard>
        <ExpandCard
          title={"Skin Conditions"}
          expanded={expandedList[2]}
          setExpanded={() => {
            let list = JSON.parse(JSON.stringify(expandedList));
            let index = list.findIndex((x: boolean) => x === true);
            if (index !== 2) {
              list[index] = false;
            }
            list[2] = !list[2];
            setExpandedList(list);
          }}
        >
          {skinConditions.map((item: url, index: number) => (
            <a
              href={item.link}
              key={index}
              className="font-sans-pro-regular color-150910-secondary-1 font-normal mb-3 font-size-14"
            >
              {item.text}
            </a>
          ))}
        </ExpandCard>
        <ExpandCard
          title={"Company"}
          expanded={expandedList[3]}
          setExpanded={() => {
            let list = JSON.parse(JSON.stringify(expandedList));
            let index = list.findIndex((x: boolean) => x === true);
            if (index !== 3) {
              list[index] = false;
            }
            list[3] = !list[3];
            setExpandedList(list);
          }}
        >
          {company.map((item: companyItems, index: number) => (
            (<Link
              key={index}
              href={item.link}
              className="cursor-pointer font-sans-pro-regular color-150910-secondary-1 font-normal mb-3 font-size-14">

              {item.text}

            </Link>)
          ))}
        </ExpandCard>
        <ExpandCard
          title={"Locations"}
          expanded={expandedList[4]}
          setExpanded={() => {
            let list = JSON.parse(JSON.stringify(expandedList));
            let index = list.findIndex((x: boolean) => x === true);
            if (index !== 4) {
              list[index] = false;
            }
            list[4] = !list[4];
            setExpandedList(list);
          }}
        >
          <Link
            href="/locations/edmonton-online-dermatology"
            className="cursor-pointer font-sans-pro-regular color-150910-secondary-1 font-normal mb-3 font-size-14">
              Edmonton - Alberta
          </Link>
          <Link
            href="/locations/vancouver-online-dermatology"
            className="cursor-pointer font-sans-pro-regular color-150910-secondary-1 font-normal mb-3 font-size-14">
              Vancouver - British Columbia
          </Link>
          <Link
            href="/locations/toronto-online-dermatology"
            className="cursor-pointer font-sans-pro-regular color-150910-secondary-1 font-normal mb-3 font-size-14">
              Toronto - Ontario
          </Link>
          <Link
            href="/locations/ottawa-online-dermatology"
            className="cursor-pointer font-sans-pro-regular color-150910-secondary-1 font-normal mb-3 font-size-14">
              Ottawa - Ontario
          </Link>
          <Link
            href="/locations"
            className="cursor-pointer font-sans-pro-regular color-150910-secondary-1 font-normal mb-3 font-size-14">
              All Cities
          </Link>
        </ExpandCard>
      </div>
      <div className="w-full flex flex-row justify-start lg:justify-center items-center mt-10 flex-wrap">
        <a
          href="https://www.instagram.com/remotederm/"
          target="_blank"
          rel="nofollow"
        >
          <div className="flex flex-row items-center mr-4 cursor-pointer lg:mb-0 mb-4">
            <img
            width={20}
            height={20}
              className="mr-2 w-5 h-5"
              alt="RemoteDerm Instagram"
              src={"https://cdn.remotederm.ca/icons/footer/instagram.webp"}
            />
            <span className="font-sans-pro-regular color-150910-secondary-1 font-size-14">
              {"Instagram"}
            </span>
            <span className="block lg:hidden ml-4 font-sans-pro-regular color-150910-secondary-1 font-size-14">
              {"/"}
            </span>
          </div>
        </a>
        <a
          href="https://www.facebook.com/Remotederm"
          target="_blank"
          rel="nofollow"
        >
          <div className="flex flex-row items-center mr-4 cursor-pointer lg:mb-0 mb-4">
            <img
             width={20}
             height={20}
              className="mr-2 w-5 h-5"
              alt="RemoteDerm Instagram"
              src={"https://cdn.remotederm.ca/icons/footer/facebook.webp"}
            />
            <span className="font-sans-pro-regular color-150910-secondary-1 font-size-14">
              {"Facebook"}
            </span>
            <span className="block lg:hidden ml-4 font-sans-pro-regular color-150910-secondary-1 font-size-14">
              {"/"}
            </span>
          </div>
        </a>
        <a
          href="https://www.linkedin.com/company/remotederm/"
          target="_blank"
          rel="nofollow"
        >
          <div className="flex flex-row items-center mr-4 cursor-pointer lg:mb-0 mb-4">
            <img
             width={20}
             height={20}
              className="mr-2 w-5 h-5"
              alt="RemoteDerm Instagram"
              src={"https://cdn.remotederm.ca/icons/footer/linkedIn.webp"}
            />
            <span className="font-sans-pro-regular color-150910-secondary-1 font-size-14">
              {"LinkedIn"}
            </span>
            <span className="block lg:hidden ml-4 font-sans-pro-regular color-150910-secondary-1 font-size-14">
              {"/"}
            </span>
          </div>
        </a>
      </div>
      <script
        src="//code.tidio.co/ef8ck4rwpv7oh9a2sziy6t0lz0hgbykl.js"
        async
      ></script>
    </footer>
  );
};

export default Footer;
